
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue';
import { useStore } from 'vuex';
import LabeledInput from '../util/LabeledInput.vue';
import { featureset } from '@/plugins/smd-feature';

type Zusammenarbeit = {
  userid: number;
  text: string;
  note: string;
};

export default defineComponent({
  props: {
    user: { type: Number, required: true },
  },
  setup(props) {
    let zusammenarbeiten = ref<Array<Zusammenarbeit>>([]);
    const newproject = ref<Zusammenarbeit>({ userid: 0, text: '', note: '' });
    const users = ref<Array<{ label: string; value: string }>>([]);
    const store = useStore();
    //zusammenarbeiten.value = [{ userid: 2, text: 'Dies ist ein Test', note: 'hi' }];
    onMounted(() => {
      if (!featureset['projektzusammenarbeit']) return;

      store.dispatch('fetchZusammenarbeit', props.user).then((res: Array<Zusammenarbeit>) => {
        if (res) {
          zusammenarbeiten.value = res;
        }
      });

      store.dispatch('fetchAllWorkers').then((res) => {
        for (const user of res) {
          users.value.push({ label: `${user.firstname} ${user.lastname}`, value: `${user.firstname} ${user.lastname}` });
        }
      });
    });

    const save = () => {
      if (!featureset['projektzusammenarbeit']) return;

      store.dispatch('updateZusammenarbeit', { id: props.user, data: zusammenarbeiten.value }).then(() => {
        store.dispatch('addNotification', { message: 'Projekte gespeichert' });
      });
    };

    const del = (index: number) => {
      zusammenarbeiten.value = [...zusammenarbeiten.value.slice(0, index), ...zusammenarbeiten.value.slice(index + 1)];
      save();
    };

    const add = () => {
      zusammenarbeiten.value.push(newproject.value);
      newproject.value = { userid: 0, text: '', note: '' };
      save();
    };

    return {
      zusammenarbeiten,
      newproject,
      add,
      users,
      save,
      del,
    };
  },
  components: { LabeledInput },
});
